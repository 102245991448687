angular.module('RocketWash').service('reservationFormPreReservations', ($injector, $uibModalStack, $uibModal, rwApi, alertService, userSession) => {
  const PRE_RESERVATION_ATTRIBUTES = ["id", "time_start", "full_duration", "user_name", "phone", "car_tag", "comment", "service_location_id", "service_location_lane_id"];

  const roundDateToMinutes = (date) => {
    const roundToMinutes = userSession.preReservationTimeStartRoundTo();
    let m = moment(date).clone();
    const changeMinutesTo = Math.round(m.minutes() / roundToMinutes) * roundToMinutes;
    return m.minutes(changeMinutesTo);
  };

  const service = {
    preReservation: {},

    timeEnd: () => {
      const timeEnd =  moment(service.preReservation.time_start).add(service.preReservation.full_duration, 'minutes');
      return timeEnd;
    },

    init: (pr) => {
      service.preReservation = angular.extend({}, pr, {
        time_start: roundDateToMinutes(pr.time_start),
        service_location_lane_id: pr.service_location_lane_id,
      });
    },

    reset: () => {
      service.preReservation = {};
    },

    save: () => {
      const rf = $injector.get('reservationForm');
      const method = service.preReservation.id ? 'PUT' : 'POST';

      let data = _.pick(service.preReservation, PRE_RESERVATION_ATTRIBUTES);

      return rwApi.sendRequest({
        method,
        path: 'reservation_form/pre_reservations',
        data,
      }).then((data) => {
        if (data.errors) {
          data.errors.forEach((error) => {
            alertService.show({
              text: error.message.ru,
              type: 'error',
            });
          });
        } else {
          $uibModalStack.dismissAll();
          rf.resetAll();
          alertService.show({
            text: 'Заказ успешно сохранен!',
            timeout: 2000,
          });
        };
      });
    },

    load: ({id} = {}) => {
      const safeId = id || service.preReservation.id;
      return rwApi.sendRequest({
        method: 'GET',
        path: 'reservation_form/pre_reservations',
        params: {
          id: safeId,
        },
      }).then((data) => {
        service.assignState(data);
      });
    },

    delete: ({id} = {}) => {
      const safeId = id || service.preReservation.id;
      return rwApi.sendRequest({
        method: 'DELETE',
        path: 'reservation_form/pre_reservations',
        params: {
          id: safeId,
          service_location_id: service.preReservation.service_location_id,
        },
      });
    },

    assignState: (data) => {
      const rf = $injector.get('reservationForm');

      service.preReservation = _.pick(data, PRE_RESERVATION_ATTRIBUTES);;
    },

    editPreReservationPopup: (preReservation) => {
      const rf = $injector.get('reservationForm');

      service.load(preReservation);
      rf.config.setPopupMode('pre_reservation');

      return $uibModal.open({
        templateUrl: `pages/wash/dashboard/reservations/create_popup.slim`,
        controller: 'WashDashboardReservationsCreateController',
        resolve: {
          preReservation: preReservation,
        },
      }).result;
    }
  }

  return service;
});
