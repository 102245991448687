angular.module('RocketWash').controller(
  'WashServicesSummaryController',
  ($q, $scope, $timeout, $translate, $http, $filter, $uibModal, BasicService, ExtraService, CarType, PriceList, plSelectService, alertService,) => {
    const SHOW_N_CAR_TYPES_DEFAULT = 7;

    $scope.plSelectService = plSelectService;

    $scope.tabIndex = 0;
    $scope.tabs = ['BasicService', 'ExtraService'];
    $scope.selected.activeCarTypes = [];

    $('.services-summary-page').on('keyup', (e) => {
      if( e.which == 9 ) {
        $timeout(() => {
          e.target.click();
        });
      }
    });

    $scope.rightMenuConfig = {
      buttons: [
        {
          type: 'export_xls',
          text: $translate.instant('buttons.export_xls'),
          action: () => { $scope.exportXLS(); },
          ngDisabled: () => (
            !$scope.canExport('BasicServicesExport')
          ),
        },
      ],
      tooltip: () => (
        !$scope.canExport('BasicServicesExport') ? $translate.instant('buttons.tooltip_message') : undefined
      ),
    };

    $scope.exportXLS = () => {
      $uibModal.open({
        templateUrl: 'pages/common/email_popup/email_popup.slim',
        controller: 'CommonEmailPopupController',
        windowClass: 'small',
        size: 'sm',
      }).result.then((email) => {
        const options = angular.extend({ email }, { car_types_group_id: $scope.ctgSelectService.selected.id });
        return $http({
          url: `${$scope.env.apiUrl}/wash/basic_services.xls`,
          method: 'GET',
          params: options,
        }).then(() => alertService.show({
          text: $filter('translate')('email_popup.success'),
        }));
      });
    };

    $scope.recalculateServiceCategories = () => {
      $scope.serviceCategories = $scope.getServiceCategories(
        $scope.basicServices
      );
      $scope.extraServiceCategories = $scope.getServiceCategories(
        $scope.extraServices
      );
    };

    $scope.isServiceGroupVisible = (services) => {
      return services.some((service) => {
        return $scope.isServiceVisible(service);
      });
    };

    $scope.isServiceVisible = (service) => {
      return service.visible;
    };

    $scope.$on('plSelectService-selected', () => {$scope.reloadServices();});
    $scope.$on('ctSelectService-selected', () => {
      $scope.selected.activeCarTypes = $scope.ctSelectService.items;
      $scope.reloadServices();
    });

    $scope.reloadServices = (options={}) => {
      if ($scope.ctSelectService.items && plSelectService.items && plSelectService.selected) {
        let ctIds = $scope.ctSelectService.items.map((ct) => ct.id);
        BasicService.query({
          price_list_id: plSelectService.selected.id,
          car_types_group_id: $scope.ctgSelectService.selected.id,
          car_type_ids: ctIds,
        }).then((data) => {
          $scope.basicServices = data.map(o => new BasicService(o));
          $scope.recalculateServiceCategories();
        });
      } else {
        $scope.basicServices = [];
        $scope.serviceCategories = [];
      }
    };

    $scope.savePLI = function (service, pli) {
      pli.price = pli.price || 0;
      pli.duration = pli.duration || 0;
      if (service.carTypesGroup.isYaFuelOnline && (pli.price <= 0 || pli.duration <= 0)) {
        alertService.show({
          text: "Запрещена нулевая прайс/длительность для услуг ЯЗО, если хотите отключить услугу это можно сделать во вкладке 'Настройка услуг', кнопка 'В архив'",
          type: 'error',
        });
        $scope.reloadServices();
        return;
      }
      if (plSelectService.selected && pli.priceListId != plSelectService.selected.id) {
        pli.priceListId = plSelectService.selected.id;
        delete pli.id;
      };
      service.save().then(() => {
        $scope.recalculateServiceCategories();
      });
    };

    $scope.deletePLI = function(service, pli) {
      let item = service.priceListItems.find((i) => i.id == pli.id);
      let index = service.priceListItems.indexOf(item);
      pli['delete']().then(() => {
        service.priceListItems.splice(index, 1);
        $scope.recalculateServiceCategories();
      });
    };

    $scope.reloadServices();
    $scope.selected.activeCarTypes = $scope.ctSelectService.items;
  },
);
