angular.module('RocketWash').service('reservationFormReservations', ($injector, $uibModalStack, $uibModal, rwApi, alertService, userSession, ActionCableChannel) => {
  const RESERVATION_ATTRIBUTES = [
    'id', 'service_location_lane_id', 'time_start', 'time_end', 'full_duration', 'comments', 'status', 'ya_fuel_online_status', 'set_employees_manually',
    'channel', 'ordinal', 'readonly', 'kkm_fiscal_check_printed_at', 'rounded_price', 'services_finished_at', 'paid_at', 'fully_paid', 'created_at', 'service_location_id'
  ];

  const service = {
    reservation: {},
    history: null,
    saving: false,

    timeEnd: () => {
      const rfs = $injector.get('reservationFormServices');
      const duration = rfs.fullServicesDuration();
      return moment(service.reservation.time_start).add(duration, 'minutes');
    },

    init: ({ service_location_id, service_location_lane_id, time_start, pre_reservation_id, camera_event_id }) => {
      const rf = $injector.get('reservationForm');

      service.reservation = {
        service_location_id,
        service_location_lane_id,
        time_start,
        pre_reservation_id,
        camera_event_id,
        channel: 'offline',
        status: 'new',
        set_employees_manually: rf.config.settings.set_employees_manually,
      }

      rf.employees.loadAll();
    },

    reset: () => {
      service.reservation = {};
      service.history = null;
      service.saving = false;
    },

    getErrors: () => {
      let errors = [];
      const rf = $injector.get('reservationForm');

      const editionDisabled = _.get(service, 'reservation.id') && userSession.isEditionForDatePrevented(_.get(service, 'reservation.time_start'));
      const statusEditable = !['cancelled', 'fail', 'cancelled_by_client', 'cancelled_by_ya_fuel_online'].includes(service.reservation.status);
      const paymentsValid = rf.payments.isValid();
      const servicesValid = rf.services.isValid();
      const carValid = rf.carContractor.isValid();

      const maxDuration = _.get(rf, 'services.total.max_duration');
      const durationInvalid = maxDuration && maxDuration < rf.r().full_duration;

      if (rf.carContractor.car.id && !carValid) {
        errors.push({
          text: 'Информация об автомобиле заполнена неверно'
        })
      }

      if (editionDisabled) {
        errors.push({
          disable_edits: true,
          text: 'Редактирование старых заказов запрещено в настройках'
        });
      };

      if (!statusEditable) {
        errors.push({
          disable_edits: true,
          text: 'Статус заказа исключает возможность редактирования'
        });
      };

      if (!paymentsValid) {
        errors.push({
          text: 'Исправьте ошибки в оплате заказа для сохранения'
        });
      };

      if (!servicesValid) {
        errors.push({
          text: 'Услуги не выбраны'
        });
      }

      if (durationInvalid) {
        errors.push({
          text: 'Расчетное время заказа превышает окно свободного времени'
        });
      }

      return errors;
    },

    canEdit: () => {
      const noErrors = _.isEmpty(service.getErrors().filter(e => e.disable_edits))
      return noErrors;
    },

    canSave: () => {
      return _.isEmpty(service.getErrors());
    },

    save: ({ closeAfterSave = true } = {}) => {
      if (service.saving) {
        return Promise.reject();
      };

      service.saving = true;

      const rf = $injector.get('reservationForm');
      const method = service.reservation.id ? 'PUT' : 'POST';

      let data = _.pick(service.reservation, RESERVATION_ATTRIBUTES);

      data.car = rf.carContractor.prepareForSave();
      data.services = rf.services.prepareForSave();
      data.payments = rf.payments.prepareForSave();
      data.selected_employees = rf.employees.prepareForSave();
      data.manual_employee_participations = rf.employeeParticipations.prepareForSave();
      data.tags = rf.marketing.prepareTagsForSave();

      const photosData = rf.photos.prepareForSave();
      data.photos = photosData.photos;
      data.recognized_photos = photosData.recognized_photos;

      data.pre_reservation_id = _.get(rf.config.getPreReservation(), 'id');

      return rwApi.sendRequest({
        method,
        path: 'reservation_form/reservations',
        data,
      }).then((data) => {
        if (!data.errors) {
          service.assignState(data);
          if (closeAfterSave) {
            $uibModalStack.dismissAll();
            rf.resetAll();
          };
          alertService.show({
            text: 'Заказ успешно сохранен!',
            timeout: 2000,
          });
        }
      }).finally(() => {
        service.saving = false;
      });
    },

    load: ({ id } = {}) => {
      service.loadingReservation = true;
      const safeId = id || service.reservation.id;
      return rwApi.sendRequest({
        method: 'GET',
        path: 'reservation_form/reservations',
        params: {
          id: safeId,
        },
      }).then((data) => {
        service.assignState(data);

        const rf = $injector.get('reservationForm');
        rf.marketing.loadAll();
        rf.services.calculate();
        rf.payments.calculate();

        return data;
      }).finally(() => {
        service.loadingReservation = false;
      });
    },

    assignState: (data) => {
      const rf = $injector.get('reservationForm');

      service.reservation = _.pick(data, RESERVATION_ATTRIBUTES);;

      rf.carContractor.assignState(data.car);
      rf.services.assignState(data.services);
      rf.payments.assignState(data.payments);
      rf.employees.assignState(data.selected_employees);
      rf.employeeParticipations.assignState(data.manual_employee_participations);
    },

    setEmployeesManuallyChanged: () => {
      if (!service.reservation.set_employees_manually) {
        const rf = $injector.get('reservationForm');
        rf.employees.reset();
        rf.employees.loadAll().then(() => {
          rf.employeeParticipations.reset();
          rf.employeeParticipations.calculate();
        });
      }
    },

    loadHistory: () => {
      if (!service.history) {
        const rf = $injector.get('reservationForm');
        const safeId = rf.carContractor.car && rf.carContractor.car.id;
        return rwApi.sendRequest({
          method: 'GET',
          path: 'reservation_form/reservations/history',
          params: {
            car_id: safeId,
          },
        }).then((data) => {
          service.history = data;
        });
      }
    },

    editReservationPopup: (reservation) => {
      const rf = $injector.get('reservationForm');

      if (reservation && reservation.id) {
        service.load({ id: reservation.id });
        rf.formTab.setTabState('form');
      }

      rf.config.loadGeneral();
      rf.config.loadDynamic();

      rf.config.setPopupMode('reservation');

      return $uibModal.open({
        templateUrl: `pages/wash/dashboard/reservations/create_popup.slim`,
        controller: 'WashDashboardReservationsCreateController',
      }).result;
    }
  };

  ['finish', 'finish_now', 'services_finished', 'back_to_work', 'cancel', 'ya_fuel_online_start_process', 'ya_fuel_online_booked'].forEach((method) => {
    service[_.camelCase(method)] = ({ closeAfterSave = true } = {}) => {
      return service.save({ closeAfterSave: false }).then(() => {
        return rwApi.sendRequest({
          method: 'POST',
          path: `reservation_form/reservations/${method}`,
          params: {
            id: service.reservation.id,
            service_location_id: service.reservation.service_location_id,
          },
        }).then((data) => {
          service.assignState(data);

          if (closeAfterSave) {
            const rf = $injector.get('reservationForm');
            rf.resetAll();

            $uibModalStack.dismissAll();
          };
        });
      });
    };
  })

  var controlPanelChannel = new ActionCableChannel("ControlPanelChannel", {
    organization_id: userSession.organization.id,
    service_location_id: userSession.service_location.id,
    name: 'control_panel',
  });
  controlPanelChannel.subscribe((data) => {
    switch (data.message) {
      case 'check_printed':
        if (data.reservation_id == service.reservation.id) {
          service.load();
          alertService.show({
            text: 'Оплата успешно зарегистрирована в ККМ!',
            timeout: 2000,
          });
        }
        break;
      case 'check_cancelled':
        if (data.reservation_id == service.reservation.id) {
          service.load();
          alertService.show({
            text: 'Ошибка оплаты в ККМ!',
            timeout: 2000,
            type: 'error',
          });
        }
        break;
    };
  });

  return service;
});
