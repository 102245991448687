angular.module('RocketWash').controller(
  'WashSettingsYaFuelOnlineIndexController',
  (
    $scope, userSession, $http, alertService, SettingValue, serverConstants, Organization, ServiceLocation
  ) => {
    $scope.userSession = userSession;

    $scope.loadServiceLocation = () => {
      ServiceLocation.get($scope.userSession.service_location.id).then((sl) => {
        $scope.sl = sl;
      });
    };

    $scope.loadServiceLocation();

    $scope.saveSlYaFuelOnline = (sl) => {
      sl.save().then(reload);
    };

    const reload = () => {
      ServiceLocation.get($scope.userSession.service_location.id).then((sl) => {
        $scope.sl = new ServiceLocation(sl);
      });
    }

    $scope.textForRegisteredBtn = function () {
      $scope.isRegistered = $scope.userSession.service_location.ya_fuel_company_id ? true : false
      if($scope.isRegistered){
        return 'Войти в личный кабинет Яндекса'
      }else{
        return 'Зарегистрироваться'
      }
    }

    const reloadSettingValues = () => {
      return SettingValue.query().then((settings) => {
        $scope.slYaFuelOnlinePhoneNumber = settings.find(x => x.key === 'service_location_phone_number_for_ya_fuel_online');
        $scope.slYaFuelOnlineAutomaticallyBook = settings.find(x => x.key === 'service_location_automatically_book_for_ya_fuel_online');

        $scope.yaFuelOnlineSettings = [
          $scope.slYaFuelOnlinePhoneNumber,
          $scope.slYaFuelOnlineAutomaticallyBook,
          ]
      });
    };
    reloadSettingValues();
    $scope.SETTING_VALUES = serverConstants.setting_values;

    $scope.save = function (setting) {
      setting.save().then(() => {
        userSession.reload();
        $route.reload();
        reloadSettingValues();
      });
    };

    $scope.yaFuelOnlineRegister = () => {
      let serviceLocationId = userSession.service_location.id;
      let payload = {
        sl_id: serviceLocationId
      };
      $http.post("/ya_fuel_online/register", payload).then((response) => {
        if(response.data.code == '200'){
          let url = response.data.result;
          window.open(url, '_blank');
        }else{
          alertService.show({
            text: 'Что-то пошло не так, обратитесь в техподдержку',
            type: 'error',
          });
        }

      });
    };

    $scope.yaFuelOnlineFinish = () => {
      let organizationId = userSession.service_location.organization.id;
      let serviceLocationId = userSession.service_location.id;
      let payload = {
        org_id: organizationId,
        sl_id: serviceLocationId
      };
      $http.post("/ya_fuel_online/finish", payload).then((response) => {
        if(response.data.code == '200'){
          alertService.show({
            text: 'Заявка на активацию отправлена успешно!',
          });
        }else{
          alertService.show({
            text: 'Что-то пошло не так, обратитесь в техподдержку',
            type: 'error',
          });
        }

      });
    };

  },
);
